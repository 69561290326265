<template>
    <div class="share-modal d-flex justify-content-center align-items-center">
        <div class="card">
            <div class="card-body">
                <div class="alert alert-info m-4" role="alert" v-if="!userStore.user.stripe">
                    <i class="bi-info-circle me-1"></i> While you're still in your free trial, you can share up to 5
                    videos.
                    You're currently at <b>{{ userStore.user.shared_videos ? userStore.user.shared_videos : 0 }}/5</b>.
                    <a :href="`${paymentLink}?prefilled_email=${userStore.user.email}&client_reference_id=${uid}`"
                        target="_blank"><strong>Upgrade now</strong></a> to share more.
                </div>

                <div class="card-title d-flex justify-content-between">
                    <h3>Share</h3>
                    <h3 class="pointer" @click="$emit('closeShareModal')"><i class="bi bi-x text-secondary"></i></h3>
                </div>
                <div class="">
                    <div class="w-100 text-center mt-4 mb-2">
                        <img class="cover me-2"
                            :src="video.reclip_cover_url || 'https://place-hold.it/144x256/6c757d?text=processing&fontsize=18'">
                    </div>
                    <div class="text-left">
                        <div class="title" v-if="!selectedProviders.includes('youtube')">
                            <div class="form-group">
                                <label for="title"><strong>Caption</strong></label>
                                <textarea class="form-control" placeholder="Caption" id="title" v-model="jobVideoData.title"
                                    rows="3"></textarea>
                            </div>
                        </div>
                        <div class="title" v-else>
                            <div class="form-group">
                                <label for="title"><strong>Title</strong></label>
                                <input class="form-control" type="text" placeholder="Title" id="title"
                                    v-model="jobVideoData.youtube.title" maxlength="100">
                            </div>
                            <div class="form-group mt-2">
                                <label for="description"><strong>Description</strong></label>
                                <textarea class="form-control" placeholder="Description" id="description"
                                    v-model="jobVideoData.youtube.description" rows="3"></textarea>
                            </div>
                        </div>
                        <div class="create-time text-sm text-secondary mt-2">{{ getDateString(video.create_time, true) }}
                        </div>
                    </div>
                </div>

                <div v-if="!shared">
                    <div class="mt-4">
                        <div class="mb-2">Share to</div>
                        <div class="form-check text-lg" v-if="providers.youtube">
                            <input class="form-check-input pointer" type="checkbox" id="youtube" value="youtube"
                                v-model="selectedProviders">
                            <label class="form-check-label pointer" for="youtube">
                                <strong><i class="bi bi-youtube text-youtube"></i> YouTube</strong>
                            </label>
                        </div>

                        <div class="form-check text-lg" v-if="providers.instagram">
                            <input class="form-check-input pointer" type="checkbox" id="instagram" value="instagram"
                                v-model="selectedProviders">
                            <label class="form-check-label pointer" for="instagram">
                                <strong><i class="bi bi-instagram text-instagram"></i> Instagram</strong>
                            </label>
                        </div>

                        <div class="form-check text-lg" v-if="providers.drive">
                            <input class="form-check-input pointer" type="checkbox" id="drive" value="drive"
                                v-model="selectedProviders">
                            <label class="form-check-label pointer" for="drive">
                                <strong><i class="bi bi-google text-drive"></i> Google Drive</strong>
                            </label>
                        </div>

                        <div class="form-check text-lg" v-if="providers.discord">
                            <input class="form-check-input pointer" type="checkbox" id="discord" value="discord"
                                v-model="selectedProviders">
                            <label class="form-check-label pointer" for="discord">
                                <strong><i class="bi bi-discord text-discord"></i> Discord</strong>
                            </label>
                        </div>

                        <!-- <div class="form-check text-lg" v-if="providers.twitter">
                            <input class="form-check-input pointer" type="checkbox" id="twitter" value="twitter"
                                v-model="selectedProviders">
                            <label class="form-check-label pointer" for="twitter">
                                <strong><i class="bi bi-twitter text-primary"></i> Twitter</strong>
                            </label>
                        </div> -->
                    </div>

                    <div v-if="selectedProviders.includes('youtube')" class="text-sm mt-4">
                        Select privacy settings for <strong>YouTube</strong>:
                        <div class="form-check">
                            <input class="form-check-input pointer" type="radio" name="public" id="public" value="public"
                                v-model="jobVideoData.youtube.privacy">
                            <label class="form-check-label pointer" for="public">
                                Public
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input pointer" type="radio" name="unlisted" id="unlisted"
                                value="unlisted" v-model="jobVideoData.youtube.privacy">
                            <label class="form-check-label pointer" for="unlisted">
                                Unlisted
                            </label>
                        </div>
                        <div class="form-check disabled">
                            <input class="form-check-input pointer" type="radio" name="private" id="private" value="private"
                                v-model="jobVideoData.youtube.privacy">
                            <label class="form-check-label pointer" for="private">
                                Private
                            </label>
                        </div>
                    </div>

                    <div class="text-center mt-4">
                        <button class="btn btn-dark btn-lg"
                            :disabled="selectedProviders.length === 0 || jobVideoData.title.length === 0 || (!userStore.user.stripe && userStore.user.shared_videos >= 5)"
                            @click="submit()">Share</button>
                    </div>
                </div>
                <div v-else>
                    <div class="text-center mt-4">
                        <button class="btn btn-success btn-lg no-pointer" :disabled="shared">
                            <i class="bi bi-check-circle"></i> Shared
                        </button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>
  
<script>
import { useUserStore } from '@/stores/user'
import { getUserId, getUser } from '@/firebase'
import { getPaymentLink } from '@/stripe'
import { createJob } from '@/firebase'

export default {
    name: 'VideoShare',
    props: ['video', 'providers'],
    setup () {
        const userStore = useUserStore()

        return {
            userStore,
        }
    },
    data () {
        return {
            uid: getUserId(),
            paymentLink: getPaymentLink(),
            selectedProviders: [],
            shared: false,
            jobVideoData: {}
        }
    },
    async created () {
        this.selectedProviders = []
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                this.$emit('closeShareModal')
            }
        })
        this.jobVideoData = { ...this.video }
        const split = this.splitString(this.video.title, 100)
        this.jobVideoData.youtube = {
            privacy: 'public',
            title: split.title,
            description: split.description
        }
    },
    methods: {
        splitString (str, lineLength) {
            let title = ''
            let description = ''

            str.split(' ').forEach(word => {
                if (title.length + word.length + 1 < lineLength) title += word + ' '
                else description += word + ' '
            })

            return {
                title: title.trim(),
                description: description.trim()
            }
        },
        getDateString (timestamp) {
            // tiktok dates are in seconds, not milliseconds
            const date = new Date(timestamp * 1000)
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
        },
        async submit () {
            await Promise.all(this.selectedProviders.map(async id => {
                await createJob(id, this.jobVideoData)
            }))
            this.selectedProviders = []
            this.shared = true
            const user = await getUser()
            this.userStore.$patch({
                user: user,
            })
            // setTimeout(() => {
            //     this.shared = false
            //     // this.$emit('closeShareModal')
            // }, 5000)

        }
    }
}
</script>
  
<style scoped>
.share-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.card {
    width: 80%;
    max-height: 95%;
    overflow: auto;
}

.cover {
    max-width: 12rem;
}

.form-check {
    min-height: auto;
}
</style>