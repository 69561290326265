<template>
    <section class="mt-5">

        <div v-if="!userStore.user.stripe && !userStore.user.is_friend">
            <div class="alert alert-info m-4" role="alert" v-if="daysLeftInTrial !== null && daysLeftInTrial > 7">
                {{ daysLeftInTrial }} days remaining in your free trial!
                <a :href="`${paymentLink}?prefilled_email=${userStore.user.email}&client_reference_id=${uid}`"
                    target="_blank" class="ms-2"><strong>Upgrade now &gt;&gt;</strong></a>
            </div>
            <div class="alert alert-warning m-4" role="alert"
                v-if="daysLeftInTrial !== null && daysLeftInTrial <= 7 && daysLeftInTrial >= 0">
                {{ daysLeftInTrial }} days remaining in your free trial!
                <a :href="`${paymentLink}?prefilled_email=${userStore.user.email}&client_reference_id=${uid}`"
                    target="_blank" class="ms-2"><strong>Upgrade now &gt;&gt;</strong></a>
            </div>
            <div class="alert alert-danger m-4" role="alert" v-if="daysLeftInTrial !== null && daysLeftInTrial < 0">
                Your free trial has expired!
                <a :href="`${paymentLink}?prefilled_email=${userStore.user.email}&client_reference_id=${uid}`"
                    target="_blank" class="ms-2"><strong>Upgrade now &gt;&gt;</strong></a>
            </div>
        </div>

        <div class="alert alert-danger m-4" role="alert" v-if="userStore.user.stripe && !userStore.user.stripe.active">
            Your subscription is inactive. Please go to the
            <a :href="`${billingLink}?prefilled_email=${userStore.user.email}`" target="_blank">billing dashboard</a> to
            continue your subscription.
        </div>

    </section>
</template> 

<script>

import { useUserStore } from '@/stores/user'
import { getUserId } from '@/firebase'
import { getPaymentLink, getBillingLink } from '@/stripe'

export default {
    name: 'TrialHeader',
    setup () {
        const userStore = useUserStore()

        return {
            userStore,
        }
    },
    async created () {
        this.calcTrial()
        setInterval(() => {
            this.calcTrial()
        }, 10 * 60000)
    },
    data () {
        return {
            uid: getUserId(),
            now: Math.floor(Date.now() / 1000),
            daysLeftInTrial: null,
            paymentLink: getPaymentLink(),
            billingLink: getBillingLink(),
        }
    },
    methods: {
        calcTrial () {
            this.now = Date.now()
            const diff = this.now - this.userStore.user.created_ms
            const diffInDays = Math.floor(diff / (1000 * 3600 * 24))
            this.daysLeftInTrial = 14 - diffInDays
        },
    }
}
</script>

<style scoped></style>