const prodConfig = {
    paymentLink: 'https://buy.stripe.com/4gwcQ8bNW7kb9MI3cc',
    billingLink: 'https://billing.stripe.com/p/login/14k9CaeJ671r5VK3cc'
}

const devConfig = {
    paymentLink: 'https://buy.stripe.com/test_cN24iq5aggMDe7C001',
    billingLink: 'https://billing.stripe.com/p/login/test_7sI7st0jbaDbfbW5kk'
}

let config = prodConfig

const host = window.location.hostname

if (host === 'localhost' || host === 'dpi.ngrok.io') {
    console.info('payment: DEV')
    config = devConfig
} else {
    console.info('payment: PROD')
}

export const getPaymentLink = () => {
    return config.paymentLink
}

export const getBillingLink = () => {
    return config.billingLink
}