<template>
    <div class="row mt-5">
        <div class="col text-center text-secondary">
            <router-link to="/dashboard" v-if="$route.path === '/videos'">Dashboard</router-link>
            <router-link to="/videos" v-else>All Videos</router-link>
            <span class="mx-2">|</span>
            <!-- <router-link to="/email">Settings</router-link> -->
            <!-- <span class="mx-2">|</span> -->
            <a :href="`${billingLink}?prefilled_email=${userStore.user.email}`" target="_blank"
                v-if="userStore.user.stripe">Billing</a>
            <a :href="`${paymentLink}?prefilled_email=${userStore.user.email}&client_reference_id=${uid}`" target="_blank"
                v-else>Upgrade</a>
            <span class="mx-2">|</span>
            <a href="#" @click="logout()">Log out</a>
        </div>
    </div>
    <div class="row mt-2 mb-5">
        <div class="col text-center text-secondary">
            <a href="mailto:hello@reclip.pro" target="_blank">Contact</a>
            <span class="mx-2">|</span>
            <a href="https://reclip.pro/terms" target="_blank">Terms of Service</a>
            <span class="mx-2">|</span>
            <a href="https://reclip.pro/privacy" target="_blank">Privacy Policy</a>
        </div>
    </div>
</template>
  
<script>
import { useUserStore } from '@/stores/user'
import { getUserId, logout } from '@/firebase'
import { getPaymentLink, getBillingLink } from '@/stripe'

export default {
    name: 'FooterNav',
    setup () {
        const userStore = useUserStore()

        return {
            userStore,
        }
    },
    data () {
        return {
            uid: getUserId(),
            paymentLink: getPaymentLink(),
            billingLink: getBillingLink()
        }
    },
    methods: {
        async logout () {
            await logout()
        },
    },
}
</script>
  
<style scoped></style>