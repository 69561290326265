<template>
  <section class="mt-5 section container">

    <trial-header></trial-header>

    <!-- YOUTUBE LOGIN -->
    <div class="m-4 p-4 row row--provider">
      <div class="col d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="d-flex align-items-center">
          <i class="bi bi-youtube text-youtube me-2"></i> YouTube
        </div>
        <div v-if="providers.youtube">
          <div class="d-flex align-items-center text-secondary">
            <span class="text-sm me-2">AUTO</span>

            <i class="bi bi-toggle-on me-4 pointer text-success" @click="toggleEnabled('youtube')"
              v-if="providers.youtube.enabled"></i>
            <i class="bi bi-toggle-off me-4 pointer" @click="toggleEnabled('youtube')" v-else></i>

            <i class="bi-sm bi-chevron-up pointer" @click="toggleInfo('youtube')" v-if="showInfo === 'youtube'"></i>
            <i class="bi-sm bi-chevron-down pointer" @click="toggleInfo('youtube')" v-else></i>
          </div>
        </div>
        <div v-else>
          <a :href="`${apiHost}/youtube/login?uid=${uid}`" class="btn btn-dark">
            Connect
          </a>
        </div>
      </div>

      <div class="info" v-if="showInfo === 'youtube'">
        <p>
          The YouTube plugin will automatically upload your new videos (without any watermarks) to
          your YouTube channel.<br>
          Videos under 60 seconds will be classified as Shorts by YouTube.
        </p>
        <p class="text-sm">
          Turn AUTO off if you want to disable automatic uploading to YouTube.<br>
          You can always manually post to your YouTube channel by clicking the <strong>Share</strong> button on a video
          on the <router-link to="/videos">All Videos</router-link> page.
        </p>
      </div>
    </div>

    <!-- INSTAGRAM LOGIN -->
    <div class="m-4 p-4 row row--provider"
      v-if="uid == 'E0mYw9fpPORnObLuNqJs' || uid == 'l1uZvoYzb6CX9lNTwnjD' || env == 'DEV'">
      <div class="col d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="d-flex align-items-center">
          <i class="bi bi-instagram text-instagram me-2"></i> Instagram
        </div>
        <div v-if="providers.instagram">
          <div class="d-flex align-items-center text-secondary">
            <span class="text-sm me-2">AUTO</span>

            <i class="bi bi-toggle-on me-4 pointer text-success" @click="toggleEnabled('instagram')"
              v-if="providers.instagram.enabled"></i>
            <i class="bi bi-toggle-off me-4 pointer" @click="toggleEnabled('instagram')" v-else></i>

            <i class="bi-sm bi-chevron-up pointer" @click="toggleInfo('instagram')" v-if="showInfo === 'instagram'"></i>
            <i class="bi-sm bi-chevron-down pointer" @click="toggleInfo('instagram')" v-else></i>
          </div>
        </div>
        <div v-else>
          <a :href="`${apiHost}/instagram/login?uid=${uid}`" class="btn btn-dark">
            Connect
          </a>
        </div>
      </div>
      <div class="info" v-if="showInfo === 'instagram'">
        <p>
          The Instagram plugin will automatically upload your new videos to Instagram Reels.
        </p>
        <p class="text-sm">
          Turn AUTO off if you want to disable automatic uploading to Instagram Reels.<br>
          You can always manually post an Instagram Reel by clicking the <strong>Share</strong> button on a
          video on the
          <router-link to="/videos">All Videos</router-link> page.
        </p>
        <div class="mt-4 text-center" v-if="providers.instagram.pages.length === 0">
          <div>
            Please reconnect and select pages & business
          </div>
          <div class="text-center mt-2">
            <a :href="`${apiHost}/instagram/login?uid=${uid}`" class="btn btn-dark">
              Connect
            </a>
          </div>
        </div>
        <div class="mt-4" v-if="providers.instagram.pages.length > 0">
          <div class="mb-3 alert alert-warning" v-if="!providers.instagram.selected_instagram_id">
            <b>Important!</b> Please choose an Instagram account you want to post to 👇
          </div>
          <div class="mb-3" v-else>
            Your selected Instagram account is:
          </div>
          <div v-for="page in providers.instagram.pages" :key="page.instagram_business_account.id">
            <div class="form-check">
              <input class="form-check-input pointer" type="radio" v-model="instagramId"
                :id="`instagram-${page.instagram_business_account.id}`" :value="`${page.instagram_business_account.id}`">
              <label class="form-check-label pointer" :for="`instagram-${page.instagram_business_account.id}`">
                <b>{{ page.instagram_business_account.name }} (@{{ page.instagram_business_account.username }})</b>
              </label>
            </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-dark" @click="saveInstagramId()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                v-if="instagramLoading"></span>
              Save
            </button>
            <span class="ms-3 text-success" v-if="instagramSaved">
              <i class="bi-sm bi-check-lg"></i>
              Saved
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- GDRIVE LOGIN -->
    <div class="m-4 p-4 row row--provider">
      <div class="col d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="d-flex align-items-center">
          <i class="bi bi-google text-drive me-2"></i> Google Drive
        </div>
        <div v-if="providers.drive">
          <div class="d-flex align-items-center text-secondary">
            <span class="text-sm me-2">AUTO</span>

            <i class="bi bi-toggle-on me-4 pointer text-success" @click="toggleEnabled('drive')"
              v-if="providers.drive.enabled"></i>
            <i class="bi bi-toggle-off me-4 pointer" @click="toggleEnabled('drive')" v-else></i>

            <i class="bi-sm bi-chevron-up pointer" @click="toggleInfo('drive')" v-if="showInfo === 'drive'"></i>
            <i class="bi-sm bi-chevron-down pointer" @click="toggleInfo('drive')" v-else></i>
          </div>
        </div>
        <div v-else>
          <a :href="`${apiHost}/drive/login?uid=${uid}`" class="btn btn-dark">
            Connect
          </a>
        </div>
      </div>
      <div class="info" v-if="showInfo === 'drive'">
        <p>
          The Google Drive plugin will automatically upload your new videos to the
          <strong>reclip.pro</strong> folder on your Google Drive account. For each posted video we will upload a
          version without watermark and a version with the TikTok watermark.
        </p>
        <p class="text-sm">
          Turn AUTO off if you want to disable automatic uploading to Google Drive.<br>
          You can always manually upload to your Google Drive folder by clicking the <strong>Share</strong> button on a
          video on the
          <router-link to="/videos">All Videos</router-link> page.
        </p>
      </div>
    </div>

    <!-- DISCORD LOGIN -->
    <div class="m-4 p-4 row row--provider">
      <div class="col d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="d-flex align-items-center">
          <i class="bi bi-discord text-discord me-2"></i> Discord
        </div>
        <div v-if="providers.discord">
          <div class="d-flex align-items-center text-secondary">
            <span class="text-sm me-2">AUTO</span>

            <i class="bi bi-toggle-on me-4 pointer text-success" @click="toggleEnabled('discord')"
              v-if="providers.discord.enabled"></i>
            <i class="bi bi-toggle-off me-4 pointer" @click="toggleEnabled('discord')" v-else></i>

            <i class="bi-sm bi-chevron-up pointer" @click="toggleInfo('discord')" v-if="showInfo === 'discord'"></i>
            <i class="bi-sm bi-chevron-down pointer" @click="toggleInfo('discord')" v-else></i>
          </div>
        </div>
        <div v-else>
          <button class="btn btn-dark" @click="toggleInfo('discord')">
            Connect
          </button>
        </div>
      </div>
      <div class="info" v-if="showInfo === 'discord'">
        <div v-if="providers.discord">
          <p>
            The Discord Webhook plugin will automatically post a link to your new video in the
            connected channel on your Discord server.
          </p>
          <p class="text-sm">
            Turn AUTO off if you want to disable automatic posting to Discord.<br>
            You can always manually post to Discord by clicking the <strong>Share</strong> button on a
            video on the
            <router-link to="/videos">All Videos</router-link> page.
          </p>
        </div>
        <div v-else>
          <div class="mt-3">
            The Discord Webhook plugin will automatically post a link to your new video in the
            connected channel on your Discord server. Please follow the instructions to setup a Discord Webhook:
          </div>
          <div class="mt-4">
            <strong>1.</strong> Open your Channel settings (<strong>"Edit Channel"</strong>).
            <img src="@/assets/discord-webhook-1.png" class="d-block mw-75" alt="Discord Edit Channel">
          </div>
          <div class="mt-4">
            <strong>2.</strong> Go to the <strong>Integrations</strong> tab and click the <strong>"Create
              Webhook"</strong> button to create a new webhook.
            <img src="@/assets/discord-webhook-2.png" class="d-block mw-75" alt="Create Webhook">
          </div>
          <div class="mt-4">
            <strong>3.</strong> Copy the Discord Webhook URL by using the <strong>"Copy Webhook URL"</strong> button.
            (Feel free to edit
            the name and avatar of the webhook)
            <img src="@/assets/discord-webhook-3.png" class="d-block mw-75" alt="Copy Webhook URL">
          </div>
          <div class="mt-4">
            <strong>4.</strong> Paste the <strong>Discord Webhook URL</strong> into the input field below and click the
            <strong>"Save"</strong> button.
          </div>
          <div class="mt-5">
            <form @submit.prevent="createProvider">
              <label for="discordWebhook" class="form-label"><strong>Discord Webhook URL</strong></label>
              <input class="form-control mb-3" id="discordWebhook" type="text"
                placeholder="https://discord.com/api/webhook/..." v-model="discordWebhook">
              <button type="submit" :disabled="discordLoading || !this.discordWebhook" class="btn btn-dark"
                @click="saveDiscordWebhook()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                  v-if="discordLoading"></span>
                Save
              </button>
            </form>
            <p class="text-danger mt-3" v-if="discordError">
              Hmmm... This doesn't look like a Discord Webhook URL.<br>
              Your Discord Webhook URL should look like this: <strong>https://discord.com/api/webhook/...</strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- TWITTER LOGIN -->
    <!-- <div class="m-4 p-4 row row--provider">
      <div class="col d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="d-flex align-items-center">
          <i class="bi bi-twitter text-primary me-2"></i>
          <span class="me-2">Twitter</span>
        </div>
        <div v-if="providers.twitter">
          <div class="d-flex align-items-center text-secondary">
            <i class="bi bi-toggle-on me-4 pointer text-success" @click="toggleEnabled('twitter')"
              v-if="providers.twitter.enabled"></i>
            <i class="bi bi-toggle-off me-4 pointer" @click="toggleEnabled('twitter')" v-else></i>

            <i class="bi-sm bi-chevron-up pointer" @click="toggleInfo('twitter')" v-if="showInfo === 'twitter'"></i>
            <i class="bi-sm bi-chevron-down pointer" @click="toggleInfo('twitter')" v-else></i>
          </div>
        </div>
        <div v-else>
          <a :href="`${apiHost}/twitter/login?uid=${uid}`" class="btn btn-dark">
            Connect
          </a>
        </div>
      </div>
      <div class="info mt-4" v-if="showInfo === 'twitter'">
        <p>
          The Twitter plugin will automatically post a link to your new TikTok video on your Twitter profile for you
          whenever you post a new video to TikTok.
        </p>
        <p class="text-sm">
          Turn AUTO off if you want to disable automatic posting to Twitter.<br>
          You can always manually share to your Twitter feed by clicking the <strong>Share</strong> button on a video on
          the <router-link to="/videos">All Videos</router-link> page.
        </p>
      </div>
    </div> -->

    <!--INSTAGRAM LOGIN -->
    <div class="m-4 p-4 row row--provider">
      <div class="col d-flex align-items-center justify-content-between flex-column flex-md-row">
        <div class="d-flex align-items-center">
          <i class="bi bi-instagram text-secondary me-2"></i>
          <span class="me-2 text-secondary">Instagram</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="text-secondary text-sm me-2" v-if="features.instagram">{{
            features.instagram.dummy_votes +
            features.instagram.votes }} VOTES</span>
          <i class="bi-hand-thumbs-up-fill me-2 text-primary pointer" @click="vote('instagram')"
            v-if="features.instagram && features.instagram.users.indexOf(uid) >= 0"></i>
          <i class="bi-hand-thumbs-up me-2 text-secondary pointer" @click="vote('instagram')" v-else></i>
          <button class="btn btn-dark" disabled>
            Coming soon
          </button>
        </div>
      </div>
    </div>

    <footer-nav></footer-nav>

  </section>
</template>

<script>

import { useUserStore } from '@/stores/user'
import { getUserId, getProviders, getFeatures, apiHost, createProvider, updateProvider, updateFeature, env } from '@/firebase'
import FooterNav from '@/components/FooterNav.vue'
import TrialHeader from '@/components/TrialHeader.vue'
import confetti from 'canvas-confetti'

export default {
  name: 'UserDashboard',
  components: { FooterNav, TrialHeader },
  setup () {
    const userStore = useUserStore()

    return {
      userStore,
    }
  },
  async created () {
    await this.loadProviders()
    await this.loadFeatures()

    if (this.$route.query.provider) {
      confetti({
        resize: true,
        useWorker: true,
        origin: { y: 1, x: 0 },
        particleCount: 120,
        angle: 60,
        spread: 55,
      })
      confetti({
        resize: true,
        useWorker: true,
        origin: { y: 1, x: 1 },
        particleCount: 120,
        angle: 120,
        spread: 55,
      })

      const providerId = this.$route.query.provider
      this.toggleInfo(providerId)
    }
  },
  data () {
    return {
      uid: getUserId(),
      videos: [],
      providers: {},
      features: {},
      apiHost,
      showInfo: null,
      instagramId: null,
      instagramLoading: false,
      instagramSaved: false,
      discordWebhook: null,
      discordLoading: false,
      discordError: false,
      env,
    }
  },
  methods: {
    async loadProviders () {
      this.providers = await getProviders()

      const instagram = this.providers['instagram']
      if (instagram && instagram.selected_instagram_id) {
        this.instagramId = instagram.selected_instagram_id
      }
    },
    async saveInstagramId () {
      this.instagramLoading = true
      const provider = this.providers['instagram']
      const instagramId = this.instagramId
      const diff = { selected_instagram_id: instagramId }
      await updateProvider('instagram', diff)
      provider.selected_instagram_id = instagramId

      this.instagramLoading = false
      this.instagramSaved = true
      setTimeout(() => {
        this.instagramSaved = false
      }, 3000)
    },
    async saveDiscordWebhook () {
      if (!this.discordWebhook) {
        return
      }
      if (this.discordWebhook.indexOf('https://discord.com/api/webhook') !== 0) {
        this.discordError = true
        return
      }
      this.discordLoading = true
      await createProvider('discord', { enabled: true, webhook_url: this.discordWebhook })

      this.discordLoading = false
      await this.loadProviders()
    },
    async loadFeatures () {
      this.features = await getFeatures()
    },
    async vote (id) {
      const feature = this.features[id]
      if (feature.users.indexOf(this.uid) >= 0) {
        feature.users = feature.users.filter((id) => id !== this.uid)
        feature.votes--
      } else {
        feature.users.push(this.uid)
        feature.votes++
      }
      await updateFeature(id, feature)
    },
    async toggleEnabled (id) {
      const provider = this.providers[id]
      const newStatus = !provider.enabled
      const diff = { enabled: newStatus }
      await updateProvider(id, diff)
      provider.enabled = newStatus
    },
    toggleInfo (provider) {
      if (this.showInfo === provider) {
        this.showInfo = null
      } else {
        this.showInfo = provider
      }
    }

  }
}
</script>

<style scoped>
.row--provider {
  background: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.bi {
  font-size: 2rem;
}

.bi-sm {
  font-size: 1rem;
}

.bi-info-circle {
  font-size: 1rem;
  line-height: 2rem;
}

.info {
  font-size: 1rem;
  font-weight: normal;
}

.mw-75 {
  max-width: 75%;
}
</style>