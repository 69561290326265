// loading the firebase config depending on environment set in config
import { initializeApp } from 'firebase/app'
import { getFirestore, doc, getDoc, getDocs, setDoc, addDoc, updateDoc, collection, query, orderBy, startAfter, limit } from 'firebase/firestore'
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword, updateProfile, updateEmail, sendEmailVerification, signOut } from 'firebase/auth'

const host = window.location.hostname

const devConfig = {
    apiKey: 'AIzaSyAzEEAXHxtq8gMC0ZthaGRLdpANw7YBpzc',
    authDomain: 'dpi-recycle.firebaseapp.com',
    projectId: 'dpi-recycle',
    databaseURL: 'https://dpi-recycle.firebaseio.com',
}
const prodConfig = {
    apiKey: 'AIzaSyDqI2CTR8eQHUjlUPguM4E6sj08_voelBM',
    authDomain: 'app.reclip.pro',
    projectId: 'reclip-app',
    databaseURL: 'https://reclip-app.firebaseio.com',
}

let config = prodConfig
if (host === 'localhost' || host === 'dpi.ngrok.io') {
    console.info('firebase: DEV')
    config = devConfig
} else {
    console.info('firebase: PROD')
}

export const env = config.projectId === 'reclip-app' ? 'PROD' : 'DEV'
export const apiHost = env === 'PROD' ? '/api' : 'https://dpi.ngrok.io/dpi-recycle/us-central1/main/api'

const app = initializeApp(config)

export const auth = getAuth(app)
auth.useDeviceLanguage()

export const db = getFirestore(app)

export const getUserId = () => {
    if (!auth.currentUser) return null
    if (auth.currentUser.email === 'demo@reclip.app') return 'l1uZvoYzb6CX9lNTwnjD'
    return auth.currentUser.uid.split(':', 2)[1]
}

export const getUser = async () => {
    if (!auth.currentUser) return null

    const uid = getUserId()
    const docRef = doc(db, 'users', uid)
    const docSnap = await getDoc(docRef)

    if (!docSnap.exists()) return null

    return docSnap.data()
}

export const updateUser = async (diff) => {
    if (!auth.currentUser) return null

    const uid = getUserId()
    const docRef = doc(db, 'users', uid)
    await updateDoc(docRef, diff)
}

export const signInWithTikTok = async (token) => {
    const res = await signInWithCustomToken(auth, token)
    const uid = getUserId()
    const docRef = doc(db, 'users', uid)
    const snap = await getDoc(docRef)
    if (snap.exists()) {
        const data = snap.data()
        await updateProfile(auth.currentUser, {
            displayName: data.display_name
        })
        // const email = data.email ? data.email : 'gen+' + uid + '@reclip.pro'
        // 
    }
    return res
}

export const demoLogin = async (email, password) => {
    await signOut(auth)
    const res = await signInWithEmailAndPassword(auth, email, password)
    return res
}

export const logout = async () => {
    await signOut(auth)
}

export const updateUserEmail = async (email) => {
    await updateEmail(auth.currentUser, email)
    await sendEmailVerification(auth.currentUser)
    const uid = getUserId()
    const userRef = doc(db, 'users', uid)
    await updateDoc(userRef, { email })
}

export const getVideos = async (lastCreateTime, paginationLimit) => {
    lastCreateTime = lastCreateTime || Math.ceil(Date.now() / 1000)
    const uid = getUserId()
    const docRef = collection(db, `users/${uid}/videos`)
    const q = query(docRef, orderBy('create_time', 'desc'), startAfter(lastCreateTime), limit(paginationLimit))
    const snap = await getDocs(q)
    return snap.docs.map((doc) => {
        return doc.data()
    })
}

export const getProviders = async () => {
    const uid = getUserId()
    const docRef = collection(db, `users/${uid}/providers`)
    const snap = await getDocs(docRef)
    const providers = {}
    snap.docs.forEach((doc) => {
        providers[doc.id] = doc.data()
    })
    return providers
}

export const getFeatures = async () => {
    const docRef = collection(db, `features`)
    const snap = await getDocs(docRef)
    const features = {}
    snap.docs.forEach((doc) => {
        features[doc.id] = doc.data()
    })
    return features
}

export const updateFeature = async (id, diff) => {
    const docRef = doc(db, `features`, id)
    await updateDoc(docRef, diff)
}

export const createProvider = async (id, diff) => {
    const uid = getUserId()
    const docRef = doc(db, `users/${uid}/providers`, id)
    await setDoc(docRef, diff)
    if(id === 'discord') {
    // fetch additional webhook info on the BE
        const res = await fetch(`${apiHost}/discord/${uid}`)  
        return await res.json()
    }
}

export const updateProvider = async (id, diff) => {
    const uid = getUserId()
    const docRef = doc(db, `users/${uid}/providers`, id)
    await updateDoc(docRef, diff)
}

export const createJob = async (providerId, video) => {
    const uid = getUserId()
    const docRef = doc(db, `users/${uid}/providers`, providerId)
    const provider = await getDoc(docRef)

    const data = {
        type: provider.id,
        provider: provider.data(),
        created_ms: Date.now(),
        uid: uid,
        video: video,
    }

    await addDoc(collection(db, 'jobs'), data)

    const user = await getUser()
    if (!user.shared_videos) user.shared_videos = 0
    await updateUser({ shared_videos: user.shared_videos + 1 })
}

