import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { onAuthStateChanged } from "firebase/auth"
import { doc, onSnapshot } from "firebase/firestore"
import { auth, getUser, getUserId, db } from '@/firebase'

import { router } from '@/router'

import { useUserStore } from '@/stores/user'

import App from './App.vue'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
const userStore = useUserStore()

let appMounted = false

const init = () => {
    if (!appMounted) {
        app.use(router)
        app.mount('#app')
        appMounted = true
    }
}

onAuthStateChanged(auth, async (authUser) => {
    if (authUser) {
        const user = await getUser()
        const isProAccount = user.stripe && user.stripe.active
        console.info('isPro', isProAccount)
        userStore.$patch({
            user: user,
            auth: authUser,
            isProAccount: isProAccount
        })

        const uid = getUserId()

        onSnapshot(doc(db, 'users', uid), (doc) => {
            const data = doc.data()
            if (data.stripe && data.stripe.active !== isProAccount) {
                window.location.reload()
            }
        })

        router.push('/dashboard')
    } else {
        userStore.$patch({
            user: null,
            auth: null,
            isProAccount: false,
        })
        router.push('/login')
    }
    init()
})
