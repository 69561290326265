<template>
    <div>
        <div class="table-responsive mt-4">
            <table class="table table-borderless">
                <tbody>
                    <tr v-if="video.share_url">
                        <td><i class="bi bi-tiktok me-1"></i> TikTok Video</td>
                        <!-- TikTok video API saves the date as a Unix timestamp in seconds, not milliseconds. -->
                        <td>{{ getDateString(video.create_time * 1000) }}</td>
                        <td><a :href="video.share_url" target="_blank">View</a></td>
                    </tr>
                    <tr v-if="video.reclip_original_url">
                        <td><i class="bi bi-play-btn me-1"></i> Video File (original)</td>
                        <!-- TikTok video API saves the date as a Unix timestamp in seconds, not milliseconds. -->
                        <td>{{ getDateString(video.create_time * 1000) }}</td>
                        <td><a :href="video.reclip_original_url" target="_blank">View</a></td>
                    </tr>
                    <tr v-if="video.reclip_watermark_url">
                        <td><i class="bi bi-play-btn me-1"></i> Video File (watermark)</td>
                        <!-- TikTok video API saves the date as a Unix timestamp in seconds, not milliseconds. -->
                        <td>{{ getDateString(video.create_time * 1000) }}</td>
                        <td><a :href="video.reclip_watermark_url" target="_blank">View</a></td>
                    </tr>
                    <tr v-if="video.youtube">
                        <td><i class="bi bi-youtube text-youtube me-1"></i> YouTube</td>
                        <td>{{ getDateString(video.youtube.date) }}</td>
                        <td><a :href="`https://www.youtube.com/watch?v=${video.youtube.id}`" target="_blank">View</a>
                        </td>
                    </tr>
                    <tr v-if="video.drive">
                        <td><i class="bi bi-google text-drive me-1"></i> Google Drive (original)</td>
                        <td>{{ getDateString(video.drive.date) }}</td>
                        <td><a :href="`https://drive.google.com/file/d/${video.drive.original_id}/view`"
                                target="_blank">View</a></td>
                    </tr>
                    <tr v-if="video.drive">
                        <td><i class="bi bi-google text-drive me-1"></i> Google Drive (watermark)</td>
                        <td>{{ getDateString(video.drive.date) }}</td>
                        <td><a :href="`https://drive.google.com/file/d/${video.drive.watermark_id}/view`"
                                target="_blank">View</a></td>
                    </tr>
                    <tr v-if="video.twitter">
                        <td><i class="bi bi-twitter text-primary me-1"></i> Twitter</td>
                        <td>{{ getDateString(video.twitter.date) }}</td>
                        <td><a :href="`https://twitter.com/twitter/status/${video.twitter.id}`" target="_blank">View</a>
                        </td>
                    </tr>
                    <tr v-if="video.instagram">
                        <td><i class="bi bi-instagram text-instagram me-1"></i> Instagram</td>
                        <td>{{ getDateString(video.instagram.date) }}</td>
                        <td><a :href="`${video.instagram.permalink}`" target="_blank">View</a>
                        </td>
                    </tr>
                    <tr v-if="video.discord">
                        <td><i class="bi bi-discord text-discord me-1"></i> Discord</td>
                        <td>{{ getDateString(video.discord.date) }}</td>
                        <td><a :href="`${video.discord.channel_url}`" target="_blank">View</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'VideoDetails',
    props: ['video'],
    data () {
        return {}
    },
    methods: {
        getDateString (timestamp) {
            const date = new Date(timestamp)
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
        }
    }
}
</script>
  
<style scoped></style>