<template>
  <nav class="navbar navbar-light bg-light px-4 justify-content-between">
    <router-link class="navbar-brand d-flex align-items-center" to="/dashboard">
      <img src="@/assets/play.png" class="d-inline-block align-top navbar-logo" alt="reclip.pro Logo">
      <span class="navbar-logo-text">reclip.pro</span>
    </router-link>
    <span v-if="userStore.user">{{ hi }}, <strong>@{{ userStore.user.display_name }}!</strong></span>
  </nav>
</template>

<script>
import { useUserStore } from '@/stores/user'

export default {
  name: 'NavBar',
  setup () {
    const userStore = useUserStore()

    return {
      userStore,
    }
  },
  async created () {
    const greetings = ['Hi', 'Howdy', 'Servus', 'Hola', 'Ciao', 'Salut', 'Ola']
    this.hi = greetings[Math.floor(Math.random() * greetings.length)]
  },
  data () {
    return {
      hi: 'Hi',
    }
  },
}
</script>

<style scoped>
.navbar {
  box-shadow: 0 2px 20px rgb(0 0 0 / 10%);
  background-color: white;
}

.navbar-logo {
  width: 40px;
  margin-right: 6px;
}

.navbar-logo-text {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
}
</style>