<template>
    <section class="section container mt-5">
        <div class="row text-center mt-5">
            <div class="col-lg-6 offset-lg-3 mx-auto mt-5">
                <div class="form-group mb-2">
                    <input type="email" class="form-control" v-model="user" placeholder="Email">
                </div>
                <div class="form-group mb-2">
                    <input type="password" class="form-control" v-model="password" placeholder="Password">
                </div>

                <button v-if="loading" disabled class="btn btn-dark btn-lg">
                    Logging you in ...
                </button>
                <button v-else class="btn btn-dark btn-lg" @click="login()">
                    Login
                </button>

                <p class="text-danger" v-if="error">{{ error }}</p>
            </div>
        </div>
        <div class="row text-center mt-3">
            <div class="col-lg-6 offset-lg-3 mx-auto">
                <p class="text-secondary">By signing up I agree to the <br>
                    <a href="https://reclip.pro/terms" target="_blank">Terms of Service</a>
                    and
                    <a href="https://reclip.pro/privacy" target="_blank">Privacy Policy</a>
                </p>
            </div>
        </div>

    </section>
</template>
  
<script>
import { demoLogin } from '@/firebase'

export default {
    name: 'DemoLogin',
    data () {
        return {
            user: null,
            password: null,
            loading: false,
            error: null,
        }
    },
    methods: {
        async login () {
            this.loading = true
            try {
                console.log(this.user, this.password)
                await demoLogin(this.user, this.password)
            } catch (e) {
                this.error = e.message
                this.loading = false
            }
        }
    }
}
</script>
  
<style scoped></style>