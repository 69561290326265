<template>
    <section class="section container mt-5">
        <div class="alert alert-info m-4" role="alert" v-if="!userStore.user.stripe && uid != 'l1uZvoYzb6CX9lNTwnjD'">
            <i class="bi-info-circle me-1"></i> While you're still in your free trial, we only import your 20 most recent
            videos from TikTok.
            <a :href="`${paymentLink}?prefilled_email=${userStore.user.email}&client_reference_id=${uid}`"
                target="_blank"><strong>Upgrade now</strong></a> to import all your videos.
        </div>

        <div class="p-4 row row--video" v-for="video in videos" :key="video.id">
            <video-share :video="video" :providers="providers" v-if="showModal && video.id === showVideoId"
                @close-share-modal="toggleModal(video.id)"></video-share>
            <div class="col">
                <div class="row">
                    <div class="col d-flex flex-lg-row flex-column align-items-center justify-content-between">
                        <img class="cover me-2"
                            :src="video.reclip_cover_url || 'https://place-hold.it/144x256/6c757d?text=processing&fontsize=18'">
                        <div class="d-flex flex-column w-100">
                            <div class="title">{{ video.title }}</div>
                            <div class="create-time text-sm text-secondary">{{ getDateString(video.create_time, true) }}
                            </div>
                        </div>
                        <div>
                            <i class="bi bi-youtube text-danger me-4" v-if="video.youtube"
                                :title="video.youtube && getDateString(video.youtube.date)"></i>
                            <i class="bi bi-youtube text-secondary me-4" v-else></i>
                            <i class="bi bi-google text-warning me-4" v-if="video.drive"
                                :title="video.drive && getDateString(video.drive.date)"></i>
                            <i class="bi bi-google text-secondary me-4" v-else></i>
                            <i class="bi bi-instagram text-instagram me-4" v-if="video.instagram"
                                :title="video.instagram && getDateString(video.instagram.date)"></i>
                            <i class="bi bi-instagram text-secondary me-4" v-else></i>
                            <!-- <i class="bi bi-twitter text-primary me-4" v-if="video.twitter"
                                :title="video.twitter && getDateString(video.twitter.date)"></i>
                            <i class="bi bi-twitter text-secondary me-4" v-else></i> -->
                        </div>
                        <div>
                            <button class="btn btn-dark" @click="toggleModal(video.id)">Share</button>
                            <i class="ms-4 bi-sm bi-chevron-up text-secondary pointer" @click="expandVideo(null)"
                                v-if="showVideoId === video.id"></i>
                            <i class="ms-4 bi-sm bi-chevron-down text-secondary pointer" @click="expandVideo(video.id)"
                                v-else></i>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="showVideoId === video.id">
                    <div class="col">
                        <video-details :video="video"></video-details>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="d-flex justify-content-between">
                <ul class="pagination pe-0">
                    <li class="page-item pointer"><a class="page-link" :class="{ disabled: limit === 10 }"
                            @click="setLimit(10)">10</a></li>
                    <li class="page-item pointer"><a class="page-link" :class="{ disabled: limit === 25 }"
                            @click="setLimit(25)">25</a></li>
                    <li class="page-item pointer"><a class="page-link" :class="{ disabled: limit === 50 }"
                            @click="setLimit(50)">50</a></li>
                </ul>
                <ul class="pagination pe-0">
                    <li class="page-item pointer">
                        <a class="page-link" :class="{ disabled: !hasPrev }" @click="prevVideos()" :aria-disabled="!hasPrev"
                            aria-label="Previous">
                            <span aria-hidden="true">&laquo; Previous</span>
                        </a>
                    </li>
                    <li class="page-item pointer">
                        <a class="page-link" :class="{ disabled: !hasNext }" @click="nextVideos()" :aria-disabled="!hasNext"
                            aria-label="Next">
                            <span aria-hidden="true">Next &raquo;</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <footer-nav></footer-nav>
    </section>
</template>
  
<script>
import { useUserStore } from '@/stores/user'
import { useVideoStore } from '@/stores/video'
import { getUserId, apiHost, getVideos, getProviders } from '@/firebase'
import { getPaymentLink } from '@/stripe'
import VideoDetails from '@/components/VideoDetails.vue'
import VideoShare from '@/components/VideoShare.vue'
import FooterNav from '@/components/FooterNav.vue'

export default {
    name: 'VideoList',
    components: { VideoDetails, VideoShare, FooterNav },
    setup () {
        const userStore = useUserStore()
        const videoStore = useVideoStore()

        return {
            userStore,
            videoStore,
        }
    },
    async created () {
        await this.loadProviders()
        this.nextVideos(true)
    },
    data () {
        return {
            apiHost,
            uid: getUserId(),
            videos: [],
            providers: {},
            start: 0,
            end: 10,
            limit: 10,
            hasNext: true,
            hasPrev: false,
            showModal: false,
            showVideoId: 0,
            paymentLink: getPaymentLink()
        }
    },
    methods: {
        async loadProviders () {
            this.providers = await getProviders()
        },
        toggleModal (videoId) {
            this.showModal = !this.showModal
            this.showVideoId = videoId
        },
        setLimit (limit) {
            this.limit = limit
            this.end = this.limit
            this.start = 0
            this.hasPrev = false
            this.hasNext = true
            this.nextVideos(true)
        },
        prevVideos () {
            if (this.start - this.limit > 0) {
                this.start -= this.limit
                this.end -= this.limit
            } else {
                this.start = 0
                this.end = this.limit
                this.hasPrev = false
            }
            this.videos = this.videoStore.videos.slice(this.start, this.end)
            this.hasNext = true
        },
        async nextVideos (init) {
            if (!init) {
                this.start += this.limit
                this.end += this.limit
            }
            if (this.start > 0) {
                this.hasPrev = true
            }
            // if we are paginating and the cursors is higher than videos in the store
            // we need to fetch a new patch
            if (this.videoStore.videos.length < this.end) {
                const lastCreateTime = this.videoStore.videos.length ? (this.videoStore.videos[this.videoStore.videos.length - 1].create_time) : 0
                const newVideos = await getVideos(lastCreateTime, this.limit)
                this.videoStore.videos.push(...newVideos)
            }

            this.videos = this.videoStore.videos.slice(this.start, this.end)
            // end of road, reset cursor to the value before loading the latest patch
            if (this.videos.length === 0) {
                this.hasNext = false
                if (!init) {
                    this.start -= this.limit
                    this.end -= this.limit
                }
                this.videos = this.videoStore.videos.slice(this.start, this.end)
            }
        },
        expandVideo (id) {
            this.showVideoId = id
        },
        getDateString (timestamp, isInMs) {
            isInMs = isInMs || false
            if (isInMs) {
                timestamp *= 1000
            }
            const date = new Date(timestamp)
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
        }
    }
}
</script>
  
<style scoped>
.row--video {
    background: #fff;
    border-bottom: 1px solid #efefef;
}

.cover {
    max-width: 5rem;
}

.title {
    max-width: 24rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bi {
    font-size: 2rem;
}

.w-100 {
    max-width: 24rem;
}
</style>